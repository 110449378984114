a {
  color: rgb(235, 210, 192);
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(235, 210, 192);
}

.quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5%;
  padding-bottom: 5%;
}

.title {
  font-weight: 100;
  padding-top: 5%;
  padding-left: 5%;
}
