.side-drawer{
    height: 100%;
    background-image:
    linear-gradient(rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)),
    url('../../assets/imgs/recorders2.jpg');
    background-position: center;
    background-size: cover;    box-shadow: 2px 0px 5px rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition:  300ms ease-out;
}

.side-drawer.open{
    transform: translateX(0);
}

.side-drawer li{
    margin-bottom: 4%;
}

.side-drawer ul{
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    list-style: none;
}

.drop-down{
    color: rgb(119, 54, 4)
}

.side-drawer a{
    color: rgb(235, 210, 192);
    text-decoration: none;

}

.dropdown-item a{
    color: rgb(119, 54, 4);
}
.side-drawer a:hover,
.side-drawer a:active{
    color: rgb(233, 145, 81);
}

.header{
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(235, 210, 192);

}

.social{
    color: rgb(235, 210, 192);
    display: flex;
    justify-content: space-around;
}

.i18n{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}