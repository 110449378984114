@import url("https://fonts.googleapis.com/css2?family=Actor&display=swap");

body {
  font-family: "Actor", sans-serif;
  margin: 0;
  color: rgb(119, 54, 4);
  background-image: linear-gradient(
      rgba(100, 65, 50, 0.7),
      rgba(100, 65, 50, 0.7)
    ),
    url("../assets/imgs/recorders1.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-box {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  padding: 15px;
  text-align: justify;
}

.text-box a {
  color: rgb(119, 54, 4);
}

iframe {
  width: 100%;
}

.content {
  padding: 3%;
}

a {
  color: rgb(173, 78, 6);
}

h4 {
  color: rgb(235, 210, 192);
}

.dark-header {
  color: rgb(173, 78, 6);
}

.between1 {
  height: 50vh;
  background-image: url("../assets/imgs/gallery/Homepage4.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.between2 {
  height: 50vh;
  background-image: url("../assets/imgs/gallery/Homepage1.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.biog-page {
  color: rgb(235, 210, 192);
}

.button-wrapper {
  height: 100px;
  padding: 4% 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons-wrapper {
  background-color: rgba(173, 78, 6, 0.6);
  color: rgb(235, 210, 192);
  padding: 1%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 3%;
  margin-bottom: 5%;
}
.contact-image {
  background-image: url("../assets//imgs/gallery//Homepage1.jpg");
  background-size: cover;
  height: 60vh;
}

.contact-middle {
  text-align: center;
  color: rgb(235, 210, 192);
}

.contact-social-icons {
  display: flex;
  justify-content: center;
}

.contact-social-icons a {
  margin: 10px;
  transition: 500ms;
}

.contact-social-icons a:hover {
  color: white;
}

.contact-newsletter-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  color: rgb(235, 210, 192);
}

.footer {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: antiquewhite;
}

.home-news {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin: 2%;
  padding: 5%;
  height: 350px;
  text-align: center;
}

.home-news img {
  max-width: 100%;
  height: 200px;
}

.home-news a {
  color: rgb(119, 54, 4);
}

.home-video {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding: 4%;
  max-width: 780px;
  height: 500px;
  margin: auto;
}

.home-middle {
  padding-bottom: 4%;
  margin-top: 15px;
}

.home-middle a:hover {
  text-decoration: none;
}

.label-name {
  border: 0.5px solid rgb(173, 78, 6);
  padding: 3px;
  color: rgb(173, 78, 6);
}

.label-name:hover {
  text-decoration: none;
  color: rgb(163, 114, 76);
}

.light-header {
  color: rgb(235, 210, 192);
}

.photo-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.photo-container img {
  max-width: 100%;
  height: 300px;
}

.post-list-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.recording-images {
  display: flex;
  margin-top: 10px;
  margin-left: 5px;
}
.recording-images a {
  margin-right: 5px;
}

.small-button {
  border: 0.5px solid rgb(119, 54, 4);
  border-radius: 15px;
  padding: 10px;
  color: rgb(173, 78, 6);
  margin: 4% 0 0 0;
}

.xsmall-button {
  border-radius: 15px;
  padding: 10px;
  color: rgb(173, 78, 6) !important;
  background-color: rgb(240, 226, 206);
  margin-bottom: 4%;
  outline: none;
  border: none;
}

.xsmall-button:hover {
  background-color: rgb(163, 114, 76);
  color: rgb(240, 226, 206) !important ;
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
}

.small-button:hover {
  background-color: rgb(163, 114, 76);
  color: rgb(240, 226, 206);
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
}

.big-button {
  color: rgb(119, 54, 4);
  padding: 2%;
  border-radius: 10px;
  background-color: rgb(240, 226, 206);
}

.big-button:hover {
  background-color: rgb(163, 114, 76);
  color: rgb(240, 226, 206);
  text-decoration: none;
  transition: 500ms;
  cursor: pointer;
  border: none;
}

.country-flag:hover {
  cursor: pointer;
}

.flags-container {
  margin-right: 10px;
}

.title-bar {
  margin-bottom: 20px;
  color: rgb(235, 210, 192);
}

.sharing-buttons {
  text-align: right;
}
/* 
  .homepage-video{
  min-height: 50vh;
  } */

.video {
  height: 100%;
}

#itunes {
  color: steelblue;
}

#itunes:hover {
  color: rgb(98, 179, 245);
}

#spotify {
  color: green;
}

#spotify:hover {
  color: rgb(5, 216, 5);
}

/* LIGHTBOX ETC */
/* image grid styles */
/* .img-grid{
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
} */
.img-wrap {
  /* overflow: hidden; */
  /* height: 0; */
  /* padding: 50% 0; */
  /* padding controls height, will always be perfectly square regardless of width */
  /* position: relative; */
  opacity: 0.8;
}
.img-wrap img {
  /* min-width: 100%; */
  /* min-height: 100%; */
  /* max-width: 150%; */
  /* position: absolute; */
  /* top: 0;
  right: 0; */
}

.photo {
  width: 100%;
  margin: 5px;
}

.photo:hover {
  cursor: pointer;
}

/* modal styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto 1px;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

.photo-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 60%;
  margin: auto;
}

.quote a:hover {
  color: rgb(163, 114, 76);
  text-decoration: none;
}
