.video{
    background-color: rgba(255, 255, 255, .8);
    color: rgb(173, 78, 6);;
    margin-bottom: 20px;
   padding: 9px;
   height: 450px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   text-align: center;
  }