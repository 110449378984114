.event {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin: 2%;
  padding: 5%;
  min-height: 350px;
  text-align: center;
}

.event a {
  color: rgb(119, 54, 4);
}

.archive-event {
  background-color: rgb(100, 47, 6);
  margin-bottom: 2%;
  margin-top: 2%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.archive-event {
  color: rgba(255, 255, 255, 0.6);
}

/* .archive-event p{
    color: rgba(255, 255, 255, .6);
     } */

.news-list {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.news-list a {
  color: rgb(100, 47, 6);
}

.recording {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 3%;
}

.recording a {
  color: rgb(119, 54, 4);
}

.project {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 1%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project img {
  height: 40vh;
  max-width: auto;
}

.project a {
  color: rgb(119, 54, 4);
}

.news-item {
  background-color: rgba(255, 255, 255, 0.6);
  color: rgb(173, 78, 6);
  margin-bottom: 5%;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: auto;
}

.news-item--text {
  max-width: 1000px;
  padding: 20px;
}

.news-item a {
  color: rgb(100, 47, 6);
}
